<template>
  <div id="sign-in-page">

    <SignIn />

  </div>
</template>

<script>
import SignIn from '@/components/settings/SignIn.vue'

export default {
  name: 'SignInPage',
  components: {
    SignIn
  }
}
</script>
