<template>
  <div id="sign-in" class="big-m-t big-m-b d-f jc-c p">
    <div class="w-100">
      <form class="d-f fd-c ai-c m-b-children">
          <p class="tt-up">Sign In</p>
          <div class="input-container w-100">
            <label class="label" for="email">Email</label>
            <input type="text" id="email" name="email" v-model="email" placeholder="Email"/>
          </div>
          <div class="input-container w-100">
            <label class="label" for="password">Password</label>
            <input type="password" id="password" name="password" v-model="password" placeholder="Password"/>
          </div>
          <button @click.prevent="login">Sign In</button>
          <router-link class="tt-up font-small" to="/sign_up">Sign Up</router-link>
      </form>
    </div>
  </div>
</template>

<script>
import { auth } from '@/db.js'

export default {
  name: 'SignIn',
  data() {
    return {
        email: '',
        password: '',
    }
  },
  methods: {
    login () {
        auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then((authData) => {
          console.log("Logged in, payload-", authData);
        })
        .then(() => {
          this.$router.push('/')
        })
        .catch(err => {
            alert(err.message)
            console.log(err.message)
        })
    },
  }

}
</script>

<style lang="scss" scoped>
// @import "@/scss/_variables.scss";
// @import "@/scss/_classes.scss";
// @import "@/scss/_mixins.scss";

</style>
